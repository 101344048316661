<template>
  <div class="prepaid">
    <div class="prepaid__content" v-loading="loading">
      <div class="prepaid__title">
        {{$t('prepaid.top_up')}}
      </div>
      <div v-if="isLegal" class="prepaid__info">
        <div class="prepaid__info-item"><span>{{$t('prepaid.make_account')}}</span></div>
        <div class="prepaid__info-item"><span>{{$t('prepaid.account_available')}}</span></div>
        <div class="prepaid__info-item"><span>{{$t('prepaid.money_comes')}}</span> </div>
      </div>
      <div v-else class="prepaid__info">
        <div class="prepaid__info-item"><span>{{ $t('prepaid.money_fee') }}</span></div>
        <div class="prepaid__info-item"><span>{{ $t('prepaid.money_delay') }}</span></div>
        <div class="prepaid__info-item"><span>{{ $t('prepaid.minimum_amount') }} — {{locale==='USA'? numberWithSpacesFix2(minPayment) : numberWithSpaces(minPayment) }} {{locale==="USA"? "USD": 'руб'}}</span></div>
        <div class="prepaid__info-item">
          <span>{{$t('prepaid.help_text')}} <router-link :to="{name: 'HelpDesk'}" @click.native="$store.dispatch('Dialog/closeDialog')">{{ $t('prepaid.help_link') }}</router-link> </span>
        </div>
      </div>
      <div class="prepaid__steps">
        <div class="prepaid__step">
          <div class="prepaid__step-title">
            1. {{$t('prepaid.how_to_top')}}
          </div>
          <div class="prepaid__step-content">
            <div v-if="paymentOptions && paymentOptions.length" class="prepaid__options">
              <label
                v-for="item in paymentOptions"
                :key="item.id"
                class="prepaid__options-item"
                :class="{'prepaid__options-item--active' : selectedPayType === item.id}"
              >
                <input
                  type="radio"
                  v-model="selectedPayType"
                  :value="item.id"
                  @change="selectedPayTypeCode = item.code"
                >
                <img
                  v-if="item.code === 'capitalist'"
                  class="prepaid__options-icon"
                  :src="getPic(['icon-capitalist.svg', 'icon-capitalist-usa.svg'])"
                  alt="main balance"
                >                
                <img
                    v-else-if="item.code === 'cashless'"
                    class="prepaid__options-icon"
                    :src="getPic(['icon-cashless.svg', 'icon-cashless-usa.svg'])"
                    alt="main balance"
                >
                <img
                    v-else-if="item.code === 'paykeeper'"
                    class="prepaid__options-icon"
                    :src="getPic(['icon-pay-card.svg', 'icon-pay-card-usa.svg'])"
                    alt="main balance"
                >
                <span class="prepaid__options-info">
                  <span v-if="item.code === 'cashless'" class="prepaid__options-value" v-html="$t('prepaid.cashless')">
                  </span>
                  <span v-else class="prepaid__options-value">
                    {{ item.title }}
                  </span>
                  <span v-if="item.code !== 'cashless' && item.code !== 'paykeeper'" class="prepaid__options-title">
                     {{$t('prepaid.account_in')}} {{$t('currency.currency_name')}}
                  </span>
                </span>
              </label>
              <label
                  v-if="role === 'finance' || role === 'admin'"
                  class="prepaid__options-item"
                  :class="{'prepaid__options-item--active' : selectedPayType === 'manual'}"
              >
                <input
                    type="radio"
                    v-model="selectedPayType"
                    :value="'manual'"
                    @change="selectedPayTypeCode = 'manual'"
                >
                <img
                    class="prepaid__options-icon"
                    :src="getPic(['icon-coins.svg', 'icon-coins-usa.svg'])"
                    alt="main balance"
                >
                <span class="prepaid__options-info">
                  <span class="prepaid__options-value">
                    {{$t('prepaid.replenishment')}}
                  </span>
                  <span class="prepaid__options-title">
                    {{$t('prepaid.manual_replenishment')}}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="prepaid__step">
          <div class="prepaid__step-title">
            2. {{$t('prepaid.account_link')}}
          </div>
          <div class="prepaid__step-content">
            <div class="prepaid__options">
              <label
                class="prepaid__options-item"
                :class="{'prepaid__options-item--active' : selectedBalance === 'RUR'}"
              >
                <input
                  type="radio"
                  v-model="selectedBalance"
                  value="RUR"
                >
                <img
                  class="prepaid__options-icon"
                  :src="getPic(['icon-wallet.svg', 'icon-wallet-red.svg'])"
                  alt="main balance"
                >
                <span class="prepaid__options-info">
                  <span class="prepaid__options-title">
                    {{$t('prepaid.ballance')}} {{$t('currency.currency_name')}}
                  </span>
                  <span class="prepaid__options-value">
                     {{ activeAccount.balance ? locale==='USA' ? numberWithSpacesFix2(activeAccount.balance) : numberWithSpaces(activeAccount.balance).split('.')[0] :0 }} {{$t('currency.currency_symbol')}}
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="prepaid__step">
          <div class="prepaid__step-title">
            3. {{$t('prepaid.transfer_details')}}
          </div>
          <!-- Currency SWITCHER -->
          <div v-if="locale === 'USA'" class="dialog-add-account__form-type dialog-add-account__form-type--radio">
            <label :class="{active: currency === 'USD'}" class="form-control">
              <input type="radio" :value="'USD'" v-model="currency">
              {{ $t('business_account_data.usd') }}
            </label>
            <label :class="{active: currency === 'EUR'}" class="form-control">
              <input type="radio" :value="'EUR'" v-model="currency">
              {{ $t('business_account_data.euro') }}
            </label>
          </div>

          <div class="prepaid__step-content prepaid__step-content--three">
            <div
              v-if="isCashless"
              class="form-input"
              :class="[{'form-input--full' : form.name_legal_entity},{'form-input--error' : errors.name_legal_entity}]"
            >
              <input v-model="form.name_legal_entity" type="text" required>
              <span class="form-input__label">{{$t('prepaid.legal')}}</span>
              <div
                v-if="errors.name_legal_entity"
                class="form-input__error"
                v-text="errors.name_legal_entity"
              />
            </div>

            <div
              v-if="isCashless && locale ==='RU'"
              class="form-input"
              :class="[{'form-input--full' : form.inn},{'form-input--error' : errors.inn}]"
            >
              <input v-model="form.inn" type="text" required>
              <span class="form-input__label">{{$t('prepaid.cpf')}}</span>
              <div
                v-if="errors.inn"
                class="form-input__error"
                v-text="errors.inn"
              />
            </div>

            <div
              v-if="isCashless && locale ==='USA'"
              class="form-input"
              :class="[{'form-input--full' : form.inn},{'form-input--error' : errors.inn}]"
            >
              <input v-model="form.inn" type="text" required>
              <span class="form-input__label">{{$t('prepaid.tax_number')}}</span>
              <div
                v-if="errors.inn"
                class="form-input__error"
                v-text="errors.inn"
              />
            </div>

            <div
                v-if="isCashless && locale ==='RU'"
                class="form-input"
                :class="[{'form-input--full' : form.kpp},{'form-input--error' : errors.kpp}]"
            >
              <input v-model="form.kpp" type="text">
              <span class="form-input__label">{{$t('prepaid.kpp')}}</span>
              <div
                  v-if="errors.kpp"
                  class="form-input__error"
                  v-text="errors.kpp"
              />
            </div>

            <div
                v-if="isCashless && locale ==='USA' && currency ==='USD'"
                class="form-input"
                :class="[{'form-input--full' : form.bank_name},{'form-input--error' : errors.bank_name}]"
            >
              <input v-model="form.bank_name" type="text">
              <span class="form-input__label">{{$t('prepaid.bank_name')}}</span>
              <div
                  v-if="errors.bank_name"
                  class="form-input__error"
                  v-text="errors.bank_name"
              />
            </div>
            <!-- Euro bank name -->
            <div
                v-if="isCashless && locale ==='USA' && currency ==='EUR'"
                class="form-input"
                :class="[{'form-input--full' : form.bank_name},{'form-input--error' : errors.bank_name}]"
            >
              <input v-model="form.bank_name" type="text">
              <span class="form-input__label">{{$t('prepaid.bank_name')}} EURO</span>
              <div
                  v-if="errors.bank_name"
                  class="form-input__error"
                  v-text="errors.bank_name"
              />
            </div>

            <div
                v-if="isCashless && locale ==='USA' && currency ==='USD'"
                class="form-input"
                :class="[{'form-input--full' : form.iban},{'form-input--error' : errors.iban}]"
            >
              <input v-model="form.iban" type="text">
              <span class="form-input__label">{{$t('prepaid.account_number')}}</span>
              <div
                  v-if="errors.iban"
                  class="form-input__error"
                  v-text="errors.iban"
              />
            </div>
            <!-- Euro account number -->
            <div
                v-if="isCashless && locale ==='USA' && currency ==='EUR'"
                class="form-input"
                :class="[{'form-input--full' : form.iban},{'form-input--error' : errors.iban}]"
            >
              <input v-model="form.iban" type="text">
              <span class="form-input__label">{{$t('prepaid.account_number')}} EURO</span>
              <div
                  v-if="errors.iban"
                  class="form-input__error"
                  v-text="errors.iban"
              />
            </div>

            <div
                v-if="isCashless && locale ==='USA'&& currency ==='USD'"
                class="form-input"
                :class="[{'form-input--full' : form.bic},{'form-input--error' : errors.bic}]"
            >
              <input v-model="form.bic" type="text">
              <span class="form-input__label">{{$t('prepaid.routing_number')}}</span>
              <div
                  v-if="errors.bic"
                  class="form-input__error"
                  v-text="errors.bic"
              />
            </div>
            <!-- Euro routing number -->
            <div
                v-if="isCashless && locale ==='USA' && currency ==='EUR'"
                class="form-input"
                :class="[{'form-input--full' : form.bic},{'form-input--error' : errors.bic}]"
            >
              <input v-model="form.bic" type="text">
              <span class="form-input__label">{{$t('prepaid.routing_number')}} EURO</span>
              <div
                  v-if="errors.bic"
                  class="form-input__error"
                  v-text="errors.bic"
              />
            </div>

            <div
                v-if="isCashless && locale ==='USA' && currency ==='USD'"
                class="form-input"
                :class="[{'form-input--full' : form.swift_code},{'form-input--error' : errors.swift_code}]"
            >
              <input v-model="form.swift_code" type="text">
              <span class="form-input__label">{{$t('prepaid.swift')}}</span>
              <div
                  v-if="errors.swift_code"
                  class="form-input__error"
                  v-text="errors.swift_code"
              />
            </div>
            <!-- EURO swift -->
            <div
                v-if="isCashless && locale ==='USA' && currency ==='EUR'"
                class="form-input"
                :class="[{'form-input--full' : form.swift_code},{'form-input--error' : errors.swift_code}]"
            >
              <input v-model="form.swift_code" type="text">
              <span class="form-input__label">{{$t('prepaid.swift')}} EURO</span>
              <div
                  v-if="errors.swift_code"
                  class="form-input__error"
                  v-text="errors.swift_code"
              />
            </div>

            <div
              v-if="isCashless && locale ==='RU'"
              class="form-input"
              :class="[{'form-input--full' : form.legal_address},{'form-input--error' : errors.legal_address}]"
            >
              <input v-model="form.legal_address" type="text" required>
              <span class="form-input__label">{{$t('prepaid.legal_address')}}</span>
              <div
                v-if="errors.legal_address"
                class="form-input__error"
                v-text="errors.legal_address"
              />
            </div>

            <div
              v-if="isCashless && locale ==='RU'"
              class="form-input"
              :class="[{'form-input--full' : form.phone},{'form-input--error' : errors.phone}]"
            >
              <input v-model="form.phone" type="text" required>
              <span class="form-input__label">{{$t('prepaid.phone')}}</span>
              <div
                v-if="errors.phone"
                class="form-input__error"
                v-text="errors.phone"
              />
            </div>

            <div
              v-if="isCashless && locale ==='USA' && currency ==='USD'"
              class="form-input"
              :class="[{'form-input--full' : form.bank_address},{'form-input--error' : errors.bank_address}]"
            >
              <input v-model="form.bank_address" type="text" required>
              <span class="form-input__label">{{$t('prepaid.bank_address')}}</span>
              <div
                v-if="errors.bank_address"
                class="form-input__error"
                v-text="errors.bank_address"
              />
            </div>
            <!-- EURO bank address -->
            <div
              v-if="isCashless && locale ==='USA' && currency ==='EUR'"
              class="form-input"
              :class="[{'form-input--full' : form.bank_address},{'form-input--error' : errors.bank_address}]"
            >
              <input v-model="form.bank_address" type="text" required>
              <span class="form-input__label">{{$t('prepaid.bank_address')}} EURO</span>
              <div
                v-if="errors.bank_address"
                class="form-input__error"
                v-text="errors.bank_address"
              />
            </div>

            <div
              class="form-input"
              :class="[{'form-input--full' : form.sum},{'form-input--error' : errors.sum}]"
            >
              <input
                v-model="form.sum"
                required
                @blur="checkValue"
              >
              <span class="form-input__label">{{$t('prepaid.transfer_amount')}}</span>
              <div class="form-input__icon">
                <!-- {{ currency ==="USD"?$t('currency.usd'):$t('currency.euro') }} -->
                {{ currency === "USD" && locale === 'USA' ? $t('currency.usd') : currency === "EUR" && locale === 'USA' ? $t('currency.euro') : $t('currency.currency_symbol') }}
              </div>
              <div
                  v-if="errors.sum"
                  class="form-input__error"
                  v-text="errors.sum"
              />
            </div>
            <div v-if="role !== 'finance'" class="prepaid__step-tip">
              {{$t('prepaid.minimum_payment')}} {{locale==='USA'? numberWithSpacesFix2(minPayment) : numberWithSpaces(minPayment) }} {{$t('currency.currency_symbol')}}
            </div>
            <!-- <div v-if="role !== 'finance' && isCashless" class="prepaid__step-tip dark">
              {{$t('prepaid.payment_message')}}
            </div> -->
            <div class="prepaid__flex-wrapper">
              <div
                class="form-input"
                :class="[{'form-input--full' : form.promo_code},{'form-input--error' : errors.promo_code}]"
              >
                <input
                  v-model="form.promo_code"
                  @blur="checkPromo"
                >
                <span class="form-input__label">{{$t('prepaid.promo_code')}}</span>
                <img v-if="promosSuccess" class="prepaid__success-icon" src="../../assets/icons/icon-check.svg" alt="Icon success"/>
                <div
                    v-if="errors.promo_code"
                    class="form-input__error"
                    v-text="errors.promo_code"
                />
              </div>
              <div class="promo-button-wrapper">
                <ButtonElement
                  v-if="!promosSuccess"
                  :text="$t('button_props.submit')"
                  :disabled="buttonDisabled || !form.promo_code || !!errors.promo_code"
                  @click-button="sendPromo"
                />
                <div v-if="promosSuccess" class="submitted">{{$t('prepaid.promo_applied')}}</div>
              </div>
            </div>
            <div v-if="promosSuccess" class="promo__success">
                  <p class="promo_trial">{{ $t('promo.expiration_date') }}  {{ formatDate(promo.expired_at) }}</p>
                  {{promo.description}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prepaid__final">
      <div class="prepaid__final-content">  
        <div v-show="selectedPayTypeCode === 'paykeeper'" class="prepaid__final-block">
          <div class="prepaid__final-sum">
            <div class="prepaid__final-sum__title">{{$t('prepaid.top-up_amount')}}:</div>
            <div class="prepaid__final-sum__value">{{ form.sum ? numberWithSpacesCeil(form.sum) : 0 }} {{$t('currency.currency_symbol')}} </div>
          </div>

          <div class="prepaid__final-nds">
            <div class="prepaid__final-nds__title">{{ !isLegal ? $t('prepaid.service_fee') : $t('prepaid.comission') }}:</div>
          <div class="prepaid__final-nds__value">{{ form.sum ? Math.ceil((form.sum / 100 * VATpercent)) : 0 }} {{$t('currency.currency_symbol')}} </div>
        </div>

          <div class="prepaid__final-total">
            <div class="prepaid__final-total__title">{{$t('prepaid.total_amount')}}</div>
            <div class="prepaid__final-total__value">
            {{ form.sum ? locale==='USA'?numberWithSpacesCeilFix2(Number(form.sum) - (form.sum / 100 * VATpercent)) : numberWithSpacesCeil(Number(form.sum) - (form.sum / 100 * VATpercent)) : 0.00 }} {{$t('currency.currency_symbol')}}
          </div>
        </div>
        
        </div>
        <div v-show="selectedPayTypeCode !== 'paykeeper'" class="prepaid__final-title">
          {{$t('prepaid.total_amount_vat')}}
        </div>
        <div v-show="selectedPayTypeCode !== 'paykeeper'" class="prepaid__final-value">
          {{ form.sum ? locale==='USA'? numberWithSpacesFix2(form.sum): numberWithSpaces(form.sum): 0  }} 
          <!-- {{currency === 'EUR'?$t('currency.euro'):$t('currency.usd')}} -->
          {{ currency === "USD" && locale === 'USA' ? $t('currency.usd') : currency === "EUR" && locale === 'USA' ? $t('currency.euro') : $t('currency.currency_symbol') }}
          
        </div>
        <ButtonElement
          :text="$t('button_props.add')"
          :disabled="buttonDisabled || !form.sum || !selectedPayType || !selectedBalance || !!errors.sum"
          @click-button="transferAmount"
        />
      </div>
        <div v-if="currency==='EUR' && locale==='USA'" class="prepaid__total-amount">
          <div v-if="currency==='EUR'" class="prepaid__cource">
              1{{$t('currency.euro')}} = {{ currentRateEUROToUSD }}{{$t('currency.usd')}}, {{$t('prepaid.total_amount_currency')}}: {{ numberWithSpacesCeilFix2(Number(form.sum) * currentRateEUROToUSD)}}{{ $t('currency.usd') }}
          </div>
        </div> 
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import numberWithSpaces from '@/mixin/numberWithSpaces'
import numberWithSpacesFix2 from '@/mixin/numberWithSpaces'

export default {
  name: 'Prepaid',
  components: {
    ButtonElement,
  },
  mixins: [formValidate, numberWithSpaces, numberWithSpacesFix2],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      selectedPayType: null,
      selectedPayTypeCode: null,
      selectedBalance: null,
      promosSuccess: false,
      promo: {
        // description :'Данный промокод начисляет дополнительные 1000 руб. на счет кэшбека сразу после активации. Промосумма может быть использована только для пополнения бюджета рекламных площадок и доступна в течение 12 месяцев после активации промокода. Вывод промосуммы на расчетный счет – невозможен.',
        // expired_at: '15.09.2023'
        description: '',
        expired_at:''
      }, 
      form: {
        name_legal_entity: '',
        inn: '',
        kpp: '',
        legal_address: '',
        phone: '',
        gateway_code: '',
        sum: '',
        bank_name: '',
        iban:'',
        bic:'',
        swift_code:'',
        bank_address:'',
        promo_code : '',
   
      },
      errors: {
        name_legal_entity: '',
        inn: '',
        kpp: '',
        legal_address: '',
        phone: '',
        gateway_code: '',
        sum: '',
        bank_name: '',
        iban:'',
        bic:'',
        swift_code:'',
        bank_address:'',
        promo_code : ''
      },
      loading: false,
      buttonDisabled: false,
      VATpercent: 2.7,
      currency: 'USD',
      additional_banks: {
        EUR: {
            bank_name : "",
            bank_address : "",
            swift_code : "",
            bic : "",
            iban : "",
            currency:"EUR"
          },
      },
      additional_banks_errors: {
        EUR: {
            bank_name : "",
            bank_address : "",
            swift_code : "",
            bic : ""
          },
      }
    }
  },
  computed: {

    formPromo() {
      return this.form.promo_code
    },

    paymentOptions() {
      return this.$store.state.Balance.paymentOptions
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    userData() {
      return this.$store.state.Auth.user
    },
    isLegal() {
      return this.userData.is_legal || this.activeAccount.is_legal
    },
    role() {
      let role = ''
      if (this.userData) {
        role = this.userData.role
      }
      return role
    },
    minPayment() {
      if ((this.role === 'finance' || this.role === 'admin') && this.selectedPayType !== 'manual') {
        return 1
      } else if (this.locale === 'USA' && this.role !== 'finance') {
        return 50
      } else if (this.role !== 'finance' && this.role !=='admin') {
        if(!this.isLegal) {
          return 10000  
        } else {
          return 30000
        }
      } else {
        return 0
      }
    },
    isCashless() {
      let checkType = false
      if (this.selectedPayType && this.paymentOptions) {
        const chosen = this.paymentOptions.find(el => el.id === this.selectedPayType)
        if (chosen && chosen.code === 'cashless') checkType = true
      }
      return checkType
    },
    switchBank() {
      return this.isCashless && this.currency !== 'USD'
    },
    errorMessage() {
     return this.$store.state.promo.errors
    },
    currentBank() {
      if (this.currency !== 'USA') {
        const filteredBanks = this.activeAccount.additional_banks.filter(item => item.currency === this.currency)
        return filteredBanks[0]
      }
      return null
    },
    currencyRates() {
      return this.$store.state.Auth.currencyRates
    },
    currentRateEUROToUSD() {
      if(this.currencyRates) {
        const rate = this.currencyRates.find(el => el.from === 'EUR' && el.to === 'USD')
        return rate.value
      }
      return null
    },
    euroError() {
      return this.additional_banks.EUR
    }
  },
  watch: {
    isCashless(val) {
      if (val) {
        this.form.name_legal_entity = this.activeAccount.legal_name
        this.form.inn = this.activeAccount.inn
        this.form.legal_address = this.activeAccount.legal_address
        this.form.phone = this.activeAccount.phone,
        this.form.bank_name = this.activeAccount.bank_name,
        this.form.iban = this.activeAccount.iban,
        this.form.bic = this.activeAccount.bic,
        this.form.swift_code = this.activeAccount.swift_code,
        this.form.bank_address = this.activeAccount.bank_address
      }
    },
    switchBank() {
      if (this.currency === 'EUR') {
        this.form.bank_name = this.currentBank.bank_name
        this.form.iban = this.currentBank.iban
        this.form.bic = this.currentBank.bic
        this.form.swift_code = this.currentBank.swift_code
        this.form.bank_address = this.currentBank.bank_address
      }
      if(this.currency === 'USD') {
        this.form.bank_name = this.activeAccount.bank_name,
        this.form.iban = this.activeAccount.iban,
        this.form.bic = this.activeAccount.bic,
        this.form.swift_code = this.activeAccount.swift_code,
        this.form.bank_address = this.activeAccount.bank_address
      }

      
    },
    selectedPayType() {
      this.checkValue()
    },
    formPromo() {
      this.checkPromo()
    },
    paymentOptions(val) {
      if (val && val.length) {
        this.selectedPayType = val[0].id
        this.selectedPayTypeCode = val[0].code
        this.selectedBalance = 'RUR'
      }
    },
    euroError: {
      deep: true,
      handler () {
        this.$store.commit('Auth/setData', { label: 'errors', data: null })
        for (const key in this.additional_banks_errors.EUR) {
          this.additional_banks_errors.EUR[key] = null
        }
      }
    }
  },
  created() {
    const params = {
      business_account_id: this.activeAccount.id
    }
    this.$store.dispatch('Balance/getPaymentOptions', params)
    this.$store.dispatch('Auth/getCurrencyRates', { business_account_id: this.activeAccount.id })
  },
  methods: {
     getPic(data) {
      let result = ''
      if(this.locale==='RU') {
        result = require(`@/assets/icons/${data[0]}`)
      } else {
        result = result = require(`@/assets/icons/${data[1]}`)
      }
      return result
    },
    validatePromo(value) {
      let regex = /[а-яА-ЯёЁ\W]+/
      return regex.test(value)
    },
    checkValue() {
      if (this.form.sum && this.minPayment && +this.form.sum < this.minPayment) {
        this.errors.sum = `${this.$t('prepaid.validation_input.min')} ${ this.minPayment }`
      } else if (!Number.isInteger(+this.form.sum) && this.minPayment) {
        this.errors.sum = this.$t('prepaid.validation_input.integer')
      } else {
        this.errors.sum = ''
      }
    },
    checkPromo() {
      if(!this.form.promo_code) this.errors.promo_code = ''
      else if(this.form.promo_code.length < 6 || this.form.promo_code.length > 10 ) this.errors.promo_code = this.$t('prepaid.promo-length-error')
      else if (this.validatePromo(this.form.promo_code)) this.errors.promo_code = this.$t('prepaid.promo-value-error')
      else this.errors.promo_code = ''
    },

    sendPromo() {
      const data = {
        id: this.activeAccount.id,
        promo: {code:this.form.promo_code},
        token: localStorage.getItem('token') || localStorage.getItem('client_token'),
      }
      this.$store.dispatch('promo/applyPromo', data)
      .then(response => {
        this.promo.description = response.data.data.description
        this.promo.expired_at = response.data.data.expired_at
        this.promosSuccess = true
      })
      .catch(error => {
        console.log(error.message)
        this.errors.promo_code = this.errorMessage.code[0]
          // this.errors.promo_code = error.message
        
      }) 
    },

    transferAmount() {
      if (this.selectedPayType && this.selectedBalance && this.form.sum && !this.buttonDisabled) {
        if (this.selectedPayType === 'manual') {
          const data = {
            data: {
              business_account_id: this.activeAccount.id,
              sum: this.form.sum,
              type_id: 10,
              currency: this.locale==='USA'? this.currency : null
            },
            token: localStorage.getItem('token')
          }

          this.buttonDisabled = true
          this.loading = true
          this.$store.dispatch('Balance/addManualPayment', data)
            .then(() => {
              this.$store.dispatch('Dialog/closeDialog')
              this.$notify({
                message: this.$t('prepaid.notify_messages.0'),
                type: 'success'
              });
            })
        } else if (this.isCashless) {
          this.validateForm()
          if (this.isError) {
            return false
          }
          const data = this.form
          data.gateway_code = 'cashless'
          data.business_account_id = this.activeAccount.id
          data.currency = this.locale==='USA'? this.currency : null
          
          if (!this.form.kpp) delete data.kpp
          this.buttonDisabled = true
          this.loading = true
          
            this.$notify({
              message: this.$t('prepaid.notify_messages.1'),
              type: 'warning',
            });
        
          this.$store.dispatch('Balance/addCashlessPayment', data)
            .then((data) => {
           
                const link = document.createElement('a');
                link.href = data.data;
                link.download = 'build.pdf';
                document.body.appendChild(link);
                link.click();
         
              this.$store.dispatch('Dialog/closeDialog')
              this.$notify({
                message: this.$t('prepaid.notify_messages.2'),
                type: 'success'
              });
            })
        } else {
          const data = {
            gateway_id: this.selectedPayType,
            currency: this.selectedBalance,
            sum: this.form.sum
          }
          this.buttonDisabled = true
          this.loading = true

          this.$store.dispatch('Balance/addOrder', { id: this.activeAccount.id, data })
            .then(({ data }) => {
              this.$store.dispatch('Balance/getLinkForOrder', { businessId: data.data.business_account_id, id: data.data.id })
                .then(({ data }) => {
                  let url = data.url + '?'
                  const dataObj = data.data
                  for (const name in dataObj) {
                    url += name + '=' + dataObj[name] + '&'
                  }
                  
                  this.$notify({
                    message: this.$t('prepaid.redirect_message'),
                    type: 'warning'
                  });
                  this.$store.dispatch('Dialog/closeDialog')
                  setTimeout(() => {
                    location.href = url;
                  }, 3000)
                })
                .catch((err) => {
                  this.$notify({
                    message: err.response.data.errors,
                    type: 'error'
                  });
                })
                .finally(() => {
                  this.loading = false
                })
            })
        }
      }
    },
    validateForm () {
      if (!this.form.name_legal_entity) {
        this.errors.name_legal_entity = this.$t('prepaid.validate_form_errors.0')
      }

      if (!this.form.inn) {
        this.errors.inn = this.$t('prepaid.validate_form_errors.1')
      }

      if (!this.form.legal_address && this.locale==='RU') {
        this.errors.legal_address = this.$t('prepaid.validate_form_errors.2')
      }

      if (!this.form.phone && this.locale==='RU') {
        this.errors.phone = this.$t('prepaid.validate_form_errors.3')
      }
    },
    formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${day}.${month}.${year}`;
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.dialog-add-account__form-type--radio {
  margin-bottom: 2rem;
}
.prepaid__flex-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
    width: 100%;
    margin-top: 2rem;
    .prepaid__success-icon {
      max-width: 21px;
      max-height: 21px;
      position: absolute;
      z-index: 0;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
    }

    .form-input {
      max-width: 50%;
      width: 100%;
      margin-bottom: 0;
    }
    .promo-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-width: 50%;
      width: 100%;
      height: 5rem;
      .submitted {
        color: #219653;
        font-size: 1.4rem;
        font-family: Roboto;
        font-weight: 400;
      }
      .button {
        height: 100%;
        width: 100%;
      }
    }
  }
.promo__success {
      .promo_trial {
        color: #BFC2C6;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 1.4rem;
        margin: 0.8rem 0 2.3rem 0;
      }

      font-family: "Roboto";
      font-weight: 400;
      font-size: 1.4rem;
      color: #7F858D;
      line-height: 16.41px;
}
.prepaid {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__total-amount {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #7f858d;
  }

  &__cource {
    font-size: 12px;
  }
  // &__total-amount {
  //   background: #EFF1F4;
  //   padding: 3.3rem 6rem;
  //   padding-top: 0;
  //   color: #333;
  //   font-size: 1.8rem;
  //   font-weight: 400;
  //   line-height: 2.2rem;
  // }

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 4.2rem;
    color: #000;
  }

  &__info {
    margin-bottom: 3.2rem;

    &-item {
      padding: 1.6rem 0;
      color: #7F858D;
      font-weight: 400;
      & + .prepaid__info-item {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }

      span {
        display: block;
        max-width: 57rem;
      }

      a {
        font-weight: 700;
        color: var(--primary);
      }
    }
  }

  &__content {
    padding: 7rem 6rem;
  }

  &__final {
    width: 100%;
    background: #EFF1F4;
    padding: 3.3rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &-title {
      white-space: nowrap;
      font-size: 1.8rem;
      line-height: 2rem;
      color: #333;
      margin-right: 2rem;
    }

    &-value {
      font-weight: bold;
      font-size: 3.5rem;
      line-height: 4.1rem;
      margin-right: 2rem;
    }

    &-block {
      width: 100%;
      padding-right: 4rem;
    }

    &-sum, &-nds, &-total {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      &__title {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.2rem;
        color: $grey1;
        margin-right: 1rem;
      }

      &__value {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 2.1rem;
        color: $basicBlack;
      }
    }

    &-total {
      margin-top: 3.6rem;

      &__title {
        color: $basicBlack;
        margin-right: 2rem;
      }

      &__value {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    }

    .button {
      width: 27rem;
      height: 4.5rem;
    }
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      &--three {
        width: 45rem;
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }

    &-tip {
      margin-top: 3rem;
      color: $gray3;
      word-break: normal;

      &.dark {
        color: $gray;
      }
    }

    &-account-selector {
      width: 40rem;
      margin-bottom: 0.6rem;
      position: relative;

      &-value {
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem 0 1.5rem;
        cursor: pointer;
      }

      &-name {
        display: flex;
        align-items: center;
        font-weight: 700;
      }

      &-options {
        position: absolute;
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        background: #fff;
        z-index: 10;
        width: 100%;
        left: 0;
        top: calc(100% + 5px);
      }

      &-item {
        padding: 0.7rem 1.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s ease-out;
        font-size: 1.4rem;

        span:first-of-type {
          font-weight: 700;
        }

        &:hover {
          color: var(--primary);
        }
      }
    }

    &-amount {
      position: relative;
      width: 38.7rem;

      &-input {
        background: #FFFFFF;
        border: 1px solid #BFC2C6;
        box-sizing: border-box;
        border-radius: 2px;
        height: 5rem;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #333;
        padding: 0 2.4rem 0 1.4rem;
        width: 100%;
      }

      &-currency {
        position: absolute;
        right: 1.4rem;
        top: 1.6rem;
        color: #7F858D;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
    }
  }

  &__options {
    display: flex;

    &-item {
      position: relative;
      background: #FFFFFF;
      border-radius: 6px;
      border: 2px solid #fff;
      box-shadow: 0 3px 10px rgba(25, 47, 79, 0.12);
      width: 24rem;
      height: 9.2rem;
      display: flex;
      align-items: center;
      padding: 0 1.8rem;
      margin-right: 2.5rem;
      transition: 0.3s ease-out;
      cursor: pointer;

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &::before {
        content: '';
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background: url("../../assets/icons/icon-done.svg") var(--primary) no-repeat center;
        background-size: 80%;
        position: absolute;
        top: 0.7rem;
        right: 0.7rem;
        opacity: 0;
      }

      &--active {
        box-shadow: none;
        border: 2px solid var(--primary);

        &::before {
          opacity: 1;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    &-icon {
      width: 3.3rem;
      margin-right: 1.4rem;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    &-value {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      margin-top: 0.7rem;
    }
  }
}

@include below(993px) {
  .prepaid__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
  }
  .prepaid__step{
    margin-bottom: 30px;
  }
  .prepaid__final,
  .prepaid__content{
    padding: 15px;
  }
  .prepaid__step-content--three{
    max-width: 100%;
  }
}

@include below(769px) {
  .prepaid__final{
    flex-wrap: wrap;
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
  .prepaid__final-value{
    font-size: 24px;
  }
  .prepaid__final-content {
    flex-direction: column;
  }
}

@include below(500px) {
  .prepaid__options{
    flex-wrap: wrap;
  }
  .prepaid__options-item{
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }

  .prepaid__final-block{
    width: 100%;
    padding-right: 0;
  }
}
</style>
